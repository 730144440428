import React from 'react'
import cx from "classnames";
import {ArticlePreview} from "@cg-squad/ui-components";

const WhatNext = (props) => {
  const randomNumber = Math.random();
    const className = cx(
        "my-4",
        props.className,
    );

    return (
      (props.recommendedPosts || []).length > 0 && <div className={className} data-datocms-noindex>
        <div className={"font-libre font-semibold text-2xl mb-2"}>{props.title || "What to read next..."}</div>
            <div className="grid grid-cols-4 gap-6">
                {props.recommendedPosts.map((article, index) => {
                    return <ArticlePreview
                      data={article}
                      key={`article-list-${randomNumber}-${index}`}
                      className={`col-span-2 lg:border-b lg:flex`}
                      gatsbyImageClass="w-full lg:w-[364px] lg:h-[236px] lg:mr-10"
                      headingClass={`font-outfit leading-none mb-1 text-2xl capitalize`}
                      textClass={"hidden lg:block line-clamp-3 mb-0"}
                      noFlag={true}/>
                })}
            </div>
        </div>
    )
}

export default WhatNext
